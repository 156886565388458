<template>
  <v-overlay
    :value="show"
  >
    <v-card
      ref="card"
      light
      width="100vw"
      height="100vh"
      class="overflow-y-auto"
    >
      <v-card-title class="flex justify-space-between teal white--text">
        Документы списком
        <v-btn
          min-width="40px"
          width="40px"
          dark
          class="elevation-0 red"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container>
        <documents-details-list
          :current-edited-id="currentlyEditedId"
          @edit="onEdit"
        />
        <document-details-edit-form
          v-if="currentlyEditedId"
          :id="currentlyEditedId"
          :payment-types="paymentTypes"
        />
      </v-container>
    </v-card>
  </v-overlay>
</template>
<script>
import {disablePageScroll} from "@/events/common";
import DocumentsDetailsList from "@/components/kdt/documents/documents-details/documents-details-list.vue";
import DocumentDetailsEditForm from "@/components/kdt/documents/documents-details/document-details-edit-form.vue";

export default {
  components: {DocumentDetailsEditForm, DocumentsDetailsList},
  props: {
    show: {
      required: true,
      type: Boolean
    },
    paymentTypes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentlyEditedId:null,
    }
  },
  computed: {

  },
  watch: {
    show: {
      handler(value) {
        disablePageScroll.trigger(value)
      },
      immediate: true
    }
  },
  methods: {
    onEdit(id){
      this.currentlyEditedId = id
    },
    close() {
      this.$emit('update:show', false)
    }
  }
}
</script>
